import CmtVertical from '@coremat/CmtNavigation/Vertical';
import { sidebar_menus } from 'constants/SideMenus';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import useClasses from 'theme/useClasses';

// import { useSelector } from 'react-redux';

const styles = () => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 207px)', //height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
});

const SideBar = () => {
  const classes = useClasses(styles());
  const authUser = useSelector(({ auth }) => auth.authUser) || {}; // routesの範囲外なので、途中でroleが更新された場合に正しく反映できるよう、セッションストレージでなくここだけreduxから取得
  const env = process.env.REACT_APP_ENV;

  // roleとenvによってメニューを変える処理
  const monitoring_children = (!!authUser?.role && sidebar_menus?.monitoring?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];
  const fm_simulator = (!!authUser?.role && sidebar_menus?.fmSimulator?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];
  const master_children = (!!authUser?.role && sidebar_menus?.master?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];
  const simulator_children = (!!authUser?.role && sidebar_menus?.simulator?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];
  const villeFeuille_children = (!!authUser?.role && sidebar_menus?.villeFeuille?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];
  const logout_children = (!!authUser?.role && sidebar_menus?.logout?.filter((elem) => elem.allowRole?.includes(authUser?.role) && elem.env?.includes(env))) || [];

  const monitoringMenus = [
    {
      name: 'MONITORING',
      type: 'section',
      children: monitoring_children,
    },
  ];

  const fmSimulatorMenus = [
    {
      name: '設備動作確認用 シミュレーター',
      type: 'section',
      children: fm_simulator,
    },
  ];

  const masterMenus = [
    {
      name: 'MASTER',
      type: 'section',
      children: master_children,
    },
  ];

  const simulatorMenus = [
    {
      name: '連携システム用 シミュレーター',
      type: 'section',
      children: simulator_children,
    },
  ];

  const villeFeuilleMenus = [
    {
      name: '三菱VILLE FEUILLE MASTER',
      type: 'section',
      children: villeFeuille_children,
    },
  ];

  const logoutMenus = [
    {
      name: 'LOGOUT',
      type: 'section',
      children: logout_children,
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      {monitoringMenus?.length !== 0 && <CmtVertical menuItems={monitoringMenus} />}
      {fmSimulatorMenus?.length !== 0 && <CmtVertical menuItems={fmSimulatorMenus} />}
      {master_children?.length !== 0 && <CmtVertical menuItems={masterMenus} />}
      {simulator_children?.length !== 0 && <CmtVertical menuItems={simulatorMenus} />}
      {villeFeuille_children?.length !== 0 && <CmtVertical menuItems={villeFeuilleMenus} />}
      {logout_children?.length !== 0 && <CmtVertical menuItems={logoutMenus} />}
    </PerfectScrollbar>
  );
};

export default SideBar;
