// import React, { Fragment } from 'react';
import zxcvbn from 'zxcvbn';
import {
  email_regex, // password_regex, postal_regex,
  number_regex,
  TEL_JP,
  ipAddress_regex,
  numAbSyb_regex,
  numAbSpace_regex,
  numAb_regex,
  postal_regex,
  number_comma_regex,
  abnumber_comma_regex,
} from 'constants/CommonConstants';
import { prefectureMenus } from 'constants/SelectMenus';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { isValueExists } from 'utils/commonHelper';
import { timeoutObj } from '../EditMasterChildDevice/helper/EditElements';
import { timeoutObj as dcTimeoutObj } from '../EditMasterDiamondDevice/helper/EditElements';

/**
 * バリデータ
 * @param {obj} eventdata
 * @param {string} name 要素のname
 * @param {value} value バリデーション対象のvalue
 * @param {number} ticketindex チケットデータのバリデーションの際の対象チケットインデックス
 * @param {obj} onChangeElem 現在入力中の要素nameとvalue（全バリデ時の開始日時と終了日時などの相互バリデの際、いま変更中要素はrenderが走るまで値が更新されない。stateの値だと入力中データとの比較ができないため取得）
 * @returns
 */
export const validator = (name, value, list = [], currentdata) => {
  if (!!name) {
    switch (name) {
      // ログイン
      case 'sec':
        return numberValidation(value);
      case 'sec1':
      case 'sec2':
      case 'sec3':
      case 'sec4':
      case 'sec5':
      case 'sec6':
        return secValidation(value);
      // 登録データ検索フィルタ
      case 'edt_diamond_device_x1':
      case 'edt_diamond_device_x2':
      case 'edt_diamond_device_y1':
      case 'edt_diamond_device_y2':
        return numberValidation(value) || stringLengthValidation(value, 6);
      case 'edt_diamond_device_bank_no':
        return numberValidation(value) || stringLengthValidation(value, 2);
      // 登録/編集バリデ
      // 敷地登録
      case 'edt_site_name':
      case 'edt_facility_name':
      case 'edt_device_name':
      case 'edt_child_device_name':
      case 'edt_organization_name':
      case 'edt_account_name':
      case 'edt_cooperation_system_name':
      case 'edt_master_cooperation_system_name':
      case 'edt_device_maker_name':
      case 'edt_diamond_connection_name':
      case 'edt_diamond_facility_name':
      case 'edt_diamond_device_name':
        return stringLengthValidation(value, 50);
      case 'edt_cooperation_system_username':
      case 'edt_device_username':
      case 'edt_diamond_connection_username':
      case 'edt_diamond_connection_password': // ここは三菱からもらったものを入れるのでzxcvbn評価はなし
        return stringLengthValidation(value, 50) || numAbSybValidation(value);
      case 'edt_cooperation_system_common_name_cert':
      case 'edt_master_cooperation_system_common_name_cert':
        return stringLengthValidation(value, 64) || numAbSpaceValidation(value);
      case 'edt_site_kana':
      case 'edt_facility_kana':
      case 'edt_device_kana':
      case 'edt_child_device_kana':
      case 'edt_organization_kana':
      case 'edt_account_kana':
      case 'edt_cooperation_system_kana':
      case 'edt_master_cooperation_system_kana':
      case 'edt_device_maker_kana':
      case 'edt_diamond_connection_kana':
      case 'edt_diamond_facility_kana':
      case 'edt_diamond_device_kana':
        return kanaValidation(value) || stringLengthValidation(value, 50);
      case 'edt_diamond_connection_vf_parent_obj_id':
      case 'edt_diamond_facility_vf_building_id':
      case 'edt_diamond_facility_vf_object_thing':
      case 'edt_diamond_facility_vf_child_obj_id':
        return numAbValidation(value) || stringLengthValidation(value, 20);
      case 'edt_child_device_call_number':
        return numAbSybValidation(value) || stringLengthValidation(value, 20);
      case 'edt_site_postalcode':
      case 'edt_facility_postalcode':
        return postalcodeValidation(value);
      case 'edt_site_pref':
      case 'edt_facility_pref':
        return levelValidation(value, prefectureMenus);
      case 'edt_site_city':
      case 'edt_facility_city':
        return stringLengthValidation(value, 10);
      case 'edt_site_address1':
      case 'edt_site_address2':
      case 'edt_site_building':
      case 'edt_facility_address1':
      case 'edt_facility_address2':
      case 'edt_facility_building':
        return stringLengthValidation(value, 50);
      case 'edt_site_tel':
      case 'edt_site_fax':
      case 'edt_facility_tel':
      case 'edt_facility_fax':
        return telValidation(value);
      case 'edt_site_lat':
      case 'edt_facility_lat':
        return stringLengthValidation(value, 40);
      case 'edt_site_lng':
      case 'edt_facility_lng':
        return stringLengthValidation(value, 45);
      case 'edt_device_ip_address':
      case 'edt_cooperation_system_ip_address':
      case 'edt_diamond_connection_ip_address':
        return ipAddressValidation(value);
      case 'edt_child_device_device_id':
        return deviceIdValidation(value, list) || stringLengthValidation(value, 10);
      case 'edt_child_device_available_floors':
        return availableFloorsValidation(value) || stringLengthValidation(value, 100);
      case 'edt_diamond_device_available_floors':
        return availableFloorsValidation2(value) || stringLengthValidation(value, 100);
      case 'edt_account_mail':
        return mailValidation(value) || stringLengthValidation(value, 50);
      case 'edt_account_password':
      case 'edt_device_password':
      case 'edt_cooperation_system_password':
        return numAbSybValidation(value) || passwdValidation(value) || stringLengthValidation(value, 50);
      // タイムアウト値設定
      case 'edt_device_timeout_delay_warn':
        return numberValidation(value) || deviceWithinNumRange(name, value, 120);
      case 'edt_device_timeout_delay_stop':
        return numberValidation(value) || deviceWithinNumRange(name, value, 60);
      case 'timeout_delay_ncCallDepRes':
      case 'timeout_delay_ncCallArrRes':
      case 'timeout_delay_spCallDepRes':
      case 'timeout_delay_spCallArrRes':
      case 'timeout_delay_afaAlmOffAns':
      case 'timeout_delay_adOpenDoorRes':
      case 'timeout_delay_adStatusOn':
      case 'timeout_delay_adPassAccept':
      case 'timeout_delay_adPassComplete':
      case 'timeout_delay_adStatusOff':
      case 'timeout_delay_elvLinkedResOn':
      case 'timeout_delay_elvInterLocking':
      case 'timeout_delay_elvBrdgCallElevatorRes':
      case 'timeout_delay_elvBrdgStatusFloor':
      case 'timeout_delay_elvBrdgStatusDoorOpenOn':
      case 'timeout_delay_elvBrdgDoorOpenRes':
      case 'timeout_delay_elvBrdgPassAccept':
      case 'timeout_delay_elvBrdgPassComplete':
      case 'timeout_delay_elvBrdgStatusDoorOpenOff':
      case 'timeout_delay_elvAltgCallElevatorRes':
      case 'timeout_delay_elvAltgStatusFloor':
      case 'timeout_delay_elvAltgStatusDoorOpenOn':
      case 'timeout_delay_elvAltgDoorOpenRes':
      case 'timeout_delay_elvAltgPassAccept':
      case 'timeout_delay_elvAltgPassComplete':
      case 'timeout_delay_elvAltgStatusDoorOpenOff':
      case 'timeout_delay_elvLinkedResOff':
      case 'timeout_delay_elvInService':
      case 'timeout_delay_tpCallRes':
      case 'timeout_delay_tpResult':
      case 'timeout_delay_tpSpCallRes':
      case 'timeout_delay_tpSpResult':
      case 'timeout_delay_wpsChargeResOn':
      case 'timeout_delay_wpsChargeReqOff':
      case 'timeout_delay_wpsChargeResOff':
      case 'timeout_delay_sgEnterDirectionOff':
      case 'timeout_delay_sgEnterOpenRes':
      case 'timeout_delay_sgEnterGateOpen':
      case 'timeout_delay_sgEnterPassAccept':
      case 'timeout_delay_sgEnterPassComplete':
      case 'timeout_delay_sgExitDirectionOff':
      case 'timeout_delay_sgExitOpenRes':
      case 'timeout_delay_sgExitGateOpen':
      case 'timeout_delay_sgExitPassAccept':
      case 'timeout_delay_sgExitPassComplete':
      case 'timeout_delay_sgFuSimulResOn':
      case 'timeout_delay_sgFuFlapOpen':
      case 'timeout_delay_sgFuPassAccept':
      case 'timeout_delay_sgFuPassComplete':
      case 'timeout_delay_sgFuSimulResOff':
      case 'timeout_delay_sgAdEnterPassAccept':
      case 'timeout_delay_sgAdEnterPassComplete':
      case 'timeout_delay_sgAdExitPassAccept':
      case 'timeout_delay_sgAdExitPassComplete':
      case 'timeout_delay_sgSoPassAccept':
      case 'timeout_delay_sgSoPassComplete':
        return numberValidation(value) || stringLengthValidation(value, 6) || withinNumRange(name, value, list);
      case 'timeout_delay_elvDcCallElevatorResult':
      case 'timeout_delay_elvDcBoarding':
      case 'timeout_delay_elvDcBrdgPassAccept':
      case 'timeout_delay_elvDcBrdgPassComplete':
      case 'timeout_delay_elvDcAlighting':
      case 'timeout_delay_elvDcAltgPassAccept':
      case 'timeout_delay_elvDcAltgPassComplete':
        return numberValidation(value) || stringLengthValidation(value, 6) || dcWithinNumRange(name, value, list);
      case 'limit_try_count_ncCallDepRes':
      case 'limit_try_count_ncCallArrRes':
      case 'limit_try_count_spCallDepRes':
      case 'limit_try_count_spCallArrRes':
      case 'limit_try_count_afaAlmOffAns':
      case 'limit_try_count_adOpenDoorRes':
      case 'limit_try_count_adStatusOn':
      case 'limit_try_count_adPassAccept':
      case 'limit_try_count_adPassComplete':
      case 'limit_try_count_adStatusOff':
      case 'limit_try_count_elvLinkedResOn':
      case 'limit_try_count_elvInterLocking':
      case 'limit_try_count_elvBrdgCallElevatorRes':
      case 'limit_try_count_elvBrdgStatusFloor':
      case 'limit_try_count_elvBrdgStatusDoorOpenOn':
      case 'limit_try_count_elvBrdgDoorOpenRes':
      case 'limit_try_count_elvBrdgPassAccept':
      case 'limit_try_count_elvBrdgPassComplete':
      case 'limit_try_count_elvBrdgStatusDoorOpenOff':
      case 'limit_try_count_elvAltgCallElevatorRes':
      case 'limit_try_count_elvAltgStatusFloor':
      case 'limit_try_count_elvAltgStatusDoorOpenOn':
      case 'limit_try_count_elvAltgDoorOpenRes':
      case 'limit_try_count_elvAltgPassAccept':
      case 'limit_try_count_elvAltgPassComplete':
      case 'limit_try_count_elvAltgStatusDoorOpenOff':
      case 'limit_try_count_elvLinkedResOff':
      case 'limit_try_count_elvInService':
      case 'limit_try_count_tpCallRes':
      case 'limit_try_count_tpResult':
      case 'limit_try_count_tpSpCallRes':
      case 'limit_try_count_tpSpResult':
      case 'limit_try_count_wpsChargeResOn':
      case 'limit_try_count_wpsChargeReqOff':
      case 'limit_try_count_wpsChargeResOff':
      case 'limit_try_count_sgEnterDirectionOff':
      case 'limit_try_count_sgEnterOpenRes':
      case 'limit_try_count_sgEnterGateOpen':
      case 'limit_try_count_sgEnterPassAccept':
      case 'limit_try_count_sgEnterPassComplete':
      case 'limit_try_count_sgExitDirectionOff':
      case 'limit_try_count_sgExitOpenRes':
      case 'limit_try_count_sgExitGateOpen':
      case 'limit_try_count_sgExitPassAccept':
      case 'limit_try_count_sgExitPassComplete':
      case 'limit_try_count_sgFuSimulResOn':
      case 'limit_try_count_sgFuFlapOpen':
      case 'limit_try_count_sgFuPassAccept':
      case 'limit_try_count_sgFuPassComplete':
      case 'limit_try_count_sgFuSimulResOff':
      case 'limit_try_count_sgAdEnterPassAccept':
      case 'limit_try_count_sgAdEnterPassComplete':
      case 'limit_try_count_sgAdExitPassAccept':
      case 'limit_try_count_sgAdExitPassComplete':
      case 'limit_try_count_sgSoPassAccept':
      case 'limit_try_count_sgSoPassComplete':
      case 'limit_try_count_elvDcCallElevatorResult':
      case 'limit_try_count_elvDcBoarding':
      case 'limit_try_count_elvDcBrdgPassAccept':
      case 'limit_try_count_elvDcBrdgPassComplete':
      case 'limit_try_count_elvDcAlighting':
      case 'limit_try_count_elvDcAltgPassAccept':
      case 'limit_try_count_elvDcAltgPassComplete':
        return numberValidation(value) || stringLengthValidation(value, 6);
      case 'mon_text':
        return logTextValidation(value);
      default:
        return '';
    }
  } else {
    return 'アプリケーションエラーです';
  }
};

const logTextValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (/[",']/.test(value)) {
    return 'シングルクォート、ダブルクォートは使用できません';
  } else {
    return '';
  }
};

const secValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (/[0-9]/.test(value)) {
    if (value.length !== 1) {
      return value.slice(-1);
    } else {
      return value;
    }
  } else {
    return '';
  }
};

const levelValidation = (value, menus = []) => {
  const levels = menus.map((menu) => menu.value);
  if (isValueExists && !levels.includes(value)) return '値が正しくありません';
  return '';
};

// const validDateTimeValidation = (value) => {
//   if (!isValueExists(value)) return '';
//   if (!!value && !isValid(value)) return '正しい日時を入力してください';
//   return '';
// };

// const validTimeValidation = value => {
//   if (!!value && !isValid(new Date(value))) return '正しい時刻を入力してください';
//   return '';
// };

// const requiredValidation = value => {
//   if (value === undefined || value === null || value === '') return '必須入力です';
//   return '';
// };

const numAbValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !numAb_regex.test(value)) {
    return '半角英数字のみ入力可能です';
  }
  return '';
};

const numAbSpaceValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !numAbSpace_regex.test(value)) {
    return '半角英数字スペースのみ入力可能です';
  }
  return '';
};

const numAbSybValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !numAbSyb_regex.test(value)) {
    return '半角英数字記号のみ入力可能です';
  }
  return '';
};

const ipAddressValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !ipAddress_regex.test(value)) {
    return 'xxx.xxx.xxx.xxx形式でご入力ください';
  }
  return '';
};

const kanaValidation = (value) => {
  if (value && !value.match(/^[ァ-ヶー　0-9]+$/)) return '全角カタカナと半角数字のみ入力可能です';
  return '';
};

const mailValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !email_regex.test(value)) {
    return '有効なメールアドレスではありません';
  }
  return '';
};

// const finiteValidation = (value) => {
//   if (!Number.isFinite(Number(value))) {
//     return '数字で入力してください';
//   }
//   if (value < 0) {
//     return 'マイナス値に設定することはできません';
//   }
//   return '';
// };

const numberValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!!value && !number_regex.test(value)) {
    return '半角数字で入力してください';
  }
  return '';
};

const stringLengthValidation = (value, length = 100) => {
  if (!isValueExists(value)) return '';
  if (value.length > length) {
    return String(length) + '文字以内にしてください';
  }
  return '';
};

// const uniqueModelValidation = (value, list = [], currentdata = {}) => {
//   if (Boolean(Array.isArray(list) && list.find(item => item.model === value && item.id !== currentdata.id))) {
//     return 'その型番はすでに登録済です';
//   }
//   return '';
// };

const passwdValidation = (value) => {
  if (!isValueExists(value)) return '';
  const result = zxcvbn(value);
  if (result.score < 4) return 'パスワードの強度が弱いです';
  // {
  //   message: 'パスワードの文字数をもう少し長くするか英数字と記号を組み合わせてみて下さい',
  //   // message: (
  //   //   <Fragment>
  //   //     パスワードの文字数をもう少し長くするか
  //   //     <br />
  //   //     英数字と記号を組み合わせてみて下さい
  //   //   </Fragment>
  //   // ),
  //   strength: result.score,
  // };
  // return { message: '', strength: result.score };
  return '';
};

const postalcodeValidation = (value) => {
  if (!isValueExists(value)) return '';
  if (!postal_regex.test(value)) {
    return '郵便番号が正しくありません';
  }
  return '';
};

const telValidation = (value) => {
  if (!isValueExists(value)) return '';
  const phoneUtil = PhoneNumberUtil.getInstance();
  // 2023/1/25 ルール明確にするためハイフンNGにした
  if (number_regex.test(value)) {
    if (value.length < 10 || value.length > 14) return '正しい桁数で入力してください';
    const telNum = phoneUtil.parseAndKeepRawInput(value, TEL_JP);
    if (!phoneUtil.isValidNumberForRegion(telNum, TEL_JP)) {
      return '電話番号が正しくありません';
    }
  } else {
    return '数字のみ入力できます';
  }
  return '';
};

const deviceIdValidation = (value, childDeviceData) => {
  if (!isValueExists(childDeviceData.thing) || childDeviceData.thing === 'none') return '先に設備種類を選択してください';
  if (value.indexOf(childDeviceData.thing) !== 0) return '接頭辞として接別種類を入れてください';

  const deviceIdNum = value.replace(childDeviceData.thing, '');
  if (!deviceIdNum) return '接頭辞に識別番号を加えてください';
  if (!!deviceIdNum && !number_regex.test(deviceIdNum)) return '接頭辞以降は半角数字で入力してください';

  return '';
};

const availableFloorsValidation = (value) => {
  if (!!value && !number_comma_regex.test(value)) {
    return '半角数字とコンマの組合わせで入力してください';
  }

  return '';
};

const availableFloorsValidation2 = (value) => {
  if (!!value && !abnumber_comma_regex.test(value)) {
    return '半角英数字とコンマの組合わせで入力してください';
  }

  return '';
};

const withinNumRange = (name, value, list) => {
  const justKey = name.replace('timeout_delay_', '');
  const targetObj = timeoutObj[list?.thing].find((obj) => obj.name === justKey);

  if (!isValueExists(value)) return '';
  if (Number(value) < Number(targetObj?.minTime) || Number(value) > Number(targetObj?.maxTime)) {
    return '範囲内の数字を入力してください';
  }

  return '';
};

const dcWithinNumRange = (name, value, list) => {
  const justKey = name.replace('timeout_delay_', '');
  const targetObj = dcTimeoutObj.find((obj) => obj.name === justKey);

  if (!isValueExists(value)) return '';
  if (Number(value) < Number(targetObj?.minTime) || Number(value) > Number(targetObj?.maxTime)) {
    return '範囲内の数字を入力してください';
  }

  return '';
};

const deviceWithinNumRange = (name, value, minTime) => {
  if (!isValueExists(value)) return '';
  if (Number(value) < minTime || Number(value) > 999) {
    return '範囲内の数字を入力してください';
  }

  return '';
};
