import { DEV, LOCAL, PROD, STG } from './CommonConstants';
import {
  FactCheckRounded,
  NotesRounded,
  BusinessRounded,
  FenceRounded,
  CorporateFareRounded,
  LeakAddRounded,
  ElectricalServicesRounded,
  AccountBoxRounded,
  SmartToyRounded,
  SettingsSystemDaydreamRounded,
  LogoutRounded,
  // BrandingWatermark,
  ReceiptLong,
  CorporateFareTwoTone,
  ElectricalServicesSharp,
  FormatListNumbered,
} from '@mui/icons-material';
import React from 'react';
import { allowRole } from './AllowRole';

// サイドメニュー一覧
export const sidebar_menus = {
  monitoring: [
    {
      name: '機器モニタリング',
      icon: <FactCheckRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/summary-monitoring-devices',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/summary-monitoring-devices'].sideMenu,
    },
    {
      name: '出力ログ',
      icon: <NotesRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/list-logging',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/list-logging'].sideMenu,
    },
  ],
  fmSimulator: [
    {
      name: '設備動作確認用 シミュレーター',
      icon: <FormatListNumbered sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/simulator',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/simulator'].sideMenu,
    },
  ],
  master: [
    {
      name: '組織マスタ',
      icon: <BusinessRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-organizations',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-organizations'].sideMenu,
    },
    {
      name: '敷地マスタ',
      icon: <FenceRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-sites',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-sites'].sideMenu,
    },
    {
      name: '施設マスタ',
      icon: <CorporateFareRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-facilities',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-facilities'].sideMenu,
    },
    {
      name: 'PLCデバイスマスタ',
      icon: <LeakAddRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-devices',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-devices'].sideMenu,
    },
    {
      name: '設備マスタ',
      icon: <ElectricalServicesRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-child-devices',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-child-devices'].sideMenu,
    },
    {
      name: 'ユーザアカウントマスタ',
      icon: <AccountBoxRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-accounts',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-accounts'].sideMenu,
    },
    {
      name: '連携システムマスタ',
      icon: <SettingsSystemDaydreamRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-master-cooperation-systems',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-master-cooperation-systems'].sideMenu,
    },
    {
      name: '各施設の登録連携システム',
      icon: <SmartToyRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-cooperation-systems',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-cooperation-systems'].sideMenu,
    },
  ],
  simulator: [
    {
      name: '敷地マスタ(シミュ)',
      icon: <FenceRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-sites-sim',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-sites-sim'].sideMenu,
    },
    {
      name: '施設マスタ(シミュ)',
      icon: <CorporateFareRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-facilities-sim',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-facilities-sim'].sideMenu,
    },
    {
      name: 'PLCデバイスマスタ(シミュ)',
      icon: <LeakAddRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-devices-sim',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-devices-sim'].sideMenu,
    },
    {
      name: '設備マスタ(シミュ)',
      icon: <ElectricalServicesRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-child-devices-sim',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-child-devices-sim'].sideMenu,
    },
    {
      name: '各施設の登録連携システム(シミュ)',
      icon: <SmartToyRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-cooperation-systems-sim',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-cooperation-systems-sim'].sideMenu,
    },
  ],
  villeFeuille: [
    {
      name: '三菱契約マスタ',
      icon: <ReceiptLong sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-diamond-connections',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-diamond-connections'].sideMenu,
    },
    {
      name: '三菱施設マスタ',
      icon: <CorporateFareTwoTone sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-diamond-facilities',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-diamond-facilities'].sideMenu,
    },
    {
      name: '三菱設備マスタ',
      icon: <ElectricalServicesSharp sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/master-diamond-devices',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/master-diamond-devices'].sideMenu,
    },
  ],
  logout: [
    {
      name: 'ログアウト',
      icon: <LogoutRounded sx={{ fontSize: 20 }} />,
      type: 'item',
      link: '/signout',
      env: [PROD, STG, DEV, LOCAL],
      allowRole: allowRole['/signout'].sideMenu,
    },
  ],
};
