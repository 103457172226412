import { ALLOW_ALL, ALLOW_SELF, EMPLOYEE, FAC_ADMIN, FAC_OWNER, FAC_USER, NOT_ALLOWED, ROBOT_ADMIN, SUPER_USER, SUPPORT } from './CommonConstants';

export const editAccountRole = {
  [SUPER_USER]: {
    [SUPER_USER]: ALLOW_ALL,
    [EMPLOYEE]: ALLOW_ALL,
    [SUPPORT]: ALLOW_ALL,
    [FAC_OWNER]: ALLOW_ALL,
    [FAC_ADMIN]: ALLOW_ALL,
    [FAC_USER]: ALLOW_ALL,
    [ROBOT_ADMIN]: ALLOW_ALL,
  },
  [EMPLOYEE]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: ALLOW_SELF,
    [SUPPORT]: NOT_ALLOWED,
    [FAC_OWNER]: NOT_ALLOWED,
    [FAC_ADMIN]: NOT_ALLOWED,
    [FAC_USER]: NOT_ALLOWED,
    [ROBOT_ADMIN]: NOT_ALLOWED,
  },
  [SUPPORT]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: NOT_ALLOWED,
    [SUPPORT]: ALLOW_SELF,
    [FAC_OWNER]: NOT_ALLOWED,
    [FAC_ADMIN]: NOT_ALLOWED,
    [FAC_USER]: NOT_ALLOWED,
    [ROBOT_ADMIN]: NOT_ALLOWED,
  },
  [FAC_OWNER]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: NOT_ALLOWED,
    [SUPPORT]: NOT_ALLOWED,
    [FAC_OWNER]: ALLOW_ALL,
    [FAC_ADMIN]: ALLOW_ALL,
    [FAC_USER]: ALLOW_ALL,
    [ROBOT_ADMIN]: NOT_ALLOWED,
  },
  [FAC_ADMIN]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: NOT_ALLOWED,
    [SUPPORT]: NOT_ALLOWED,
    [FAC_OWNER]: NOT_ALLOWED,
    [FAC_ADMIN]: ALLOW_SELF,
    [FAC_USER]: ALLOW_ALL,
    [ROBOT_ADMIN]: NOT_ALLOWED,
  },
  [FAC_USER]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: NOT_ALLOWED,
    [SUPPORT]: NOT_ALLOWED,
    [FAC_OWNER]: NOT_ALLOWED,
    [FAC_ADMIN]: NOT_ALLOWED,
    [FAC_USER]: ALLOW_SELF,
    [ROBOT_ADMIN]: NOT_ALLOWED,
  },
  [ROBOT_ADMIN]: {
    [SUPER_USER]: NOT_ALLOWED,
    [EMPLOYEE]: NOT_ALLOWED,
    [SUPPORT]: NOT_ALLOWED,
    [FAC_OWNER]: NOT_ALLOWED,
    [FAC_ADMIN]: NOT_ALLOWED,
    [FAC_USER]: NOT_ALLOWED,
    [ROBOT_ADMIN]: ALLOW_SELF,
  },
};

export const allowRole = {
  '/summary-monitoring-devices': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    monitor: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    csvDl: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
  },
  '/list-device-operations': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    monitor: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    csvDl: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
  },
  '/list-logging': {
    sideMenu: [SUPER_USER, SUPPORT], // [SUPER_USER, EMPLOYEE, SUPPORT], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「プラットフォーム管理者」シート指摘対応
    monitor: [SUPER_USER, SUPPORT], // [SUPER_USER, EMPLOYEE, SUPPORT], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「プラットフォーム管理者」シート指摘対応
    csvDl: [SUPER_USER, SUPPORT], // [SUPER_USER, EMPLOYEE, SUPPORT], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「プラットフォーム管理者」シート指摘対応
  },
  '/simulator': {
    sideMenu: [SUPER_USER, SUPPORT], // [SUPER_USER, EMPLOYEE, SUPPORT], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「プラットフォーム管理者」シート指摘対応
    monitor: [SUPER_USER, SUPPORT], // [SUPER_USER, EMPLOYEE, SUPPORT], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「プラットフォーム管理者」シート指摘対応
  },
  '/master-organizations': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT],
    search: [SUPER_USER, EMPLOYEE, SUPPORT],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-organization': {
    entry: [SUPER_USER],
    read: [SUPER_USER, EMPLOYEE, SUPPORT],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-sites': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-site': {
    entry: [SUPER_USER],
    read: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-sites-sim': {
    sideMenu: [SUPER_USER, SUPPORT],
    search: [SUPER_USER, SUPPORT],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-site-sim': {
    entry: [SUPER_USER],
    read: [SUPER_USER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-facilities': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-facility': {
    entry: [SUPER_USER],
    read: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-facilities-sim': {
    sideMenu: [SUPER_USER, SUPPORT],
    search: [SUPER_USER, SUPPORT],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-facility-sim': {
    entry: [SUPER_USER],
    read: [SUPER_USER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-devices': {
    sideMenu: [SUPER_USER, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-device': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-devices-sim': {
    sideMenu: [SUPER_USER, SUPPORT],
    search: [SUPER_USER, SUPPORT],
    csvDl: [SUPER_USER],
    run: [SUPER_USER],
  },
  '/master-child-devices': {
    sideMenu: [SUPER_USER, SUPPORT], //, FAC_OWNER, FAC_ADMIN, ROBOT_ADMIN], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「機能権限一覧_サイドメニュー単位」指摘対応
    search: [SUPER_USER, SUPPORT], //, FAC_OWNER, FAC_ADMIN, ROBOT_ADMIN], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「機能権限一覧_サイドメニュー単位」指摘対応
    csvDl: [SUPER_USER], //, ROBOT_ADMIN], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「機能権限一覧_サイドメニュー単位」指摘対応
    newEntry: [SUPER_USER],
  },
  '/edit-master-child-device': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT], // , FAC_OWNER, FAC_ADMIN, ROBOT_ADMIN], ※20231221『230901_RoboHUB-IM_権限 (提示).xlsx』の「機能権限一覧_サイドメニュー単位」指摘対応
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-child-devices-sim': {
    sideMenu: [SUPER_USER, SUPPORT, ROBOT_ADMIN],
    search: [SUPER_USER, SUPPORT, ROBOT_ADMIN],
    csvDl: [SUPER_USER, ROBOT_ADMIN],
    afaControl: [SUPER_USER, ROBOT_ADMIN],
    elvControl: [SUPER_USER, ROBOT_ADMIN],
    wpsControl: [SUPER_USER, ROBOT_ADMIN],
    sgControl: [SUPER_USER, ROBOT_ADMIN],
  },
  '/master-accounts': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    search: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    csvDl: [SUPER_USER, FAC_OWNER],
    newEntry: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
  },
  '/edit-master-account': {
    entry: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    read: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    edit: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
    delete: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
  },
  '/master-cooperation-systems': {
    sideMenu: [SUPER_USER, SUPPORT, FAC_OWNER, ROBOT_ADMIN],
    search: [SUPER_USER, SUPPORT, FAC_OWNER, ROBOT_ADMIN],
    csvDl: [SUPER_USER, FAC_OWNER, ROBOT_ADMIN],
    newEntry: [SUPER_USER],
  },
  '/edit-master-cooperation-system': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT, FAC_OWNER, ROBOT_ADMIN],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-cooperation-systems-sim': {
    sideMenu: [SUPER_USER, SUPPORT, ROBOT_ADMIN],
    search: [SUPER_USER, SUPPORT, ROBOT_ADMIN],
    csvDl: [SUPER_USER, ROBOT_ADMIN],
    newEntry: [SUPER_USER],
  },
  '/edit-master-cooperation-system-sim': {
    entry: [SUPER_USER],
    read: [SUPER_USER, ROBOT_ADMIN],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-diamond-connections': {
    sideMenu: [SUPER_USER, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-diamond-connection': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-diamond-facilities': {
    sideMenu: [SUPER_USER, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-diamond-facility': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-diamond-devices': {
    sideMenu: [SUPER_USER, SUPPORT, FAC_OWNER],
    search: [SUPER_USER, SUPPORT, FAC_OWNER],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-diamond-device': {
    entry: [SUPER_USER],
    read: [SUPER_USER, SUPPORT, FAC_OWNER],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/master-master-cooperation-systems': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT],
    search: [SUPER_USER, EMPLOYEE, SUPPORT],
    csvDl: [SUPER_USER],
    newEntry: [SUPER_USER],
  },
  '/edit-master-master-cooperation-system': {
    entry: [SUPER_USER],
    read: [SUPER_USER, EMPLOYEE, SUPPORT],
    edit: [SUPER_USER],
    delete: [SUPER_USER],
  },
  '/signout': {
    sideMenu: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER, ROBOT_ADMIN],
  },
};
