import ErrorBoundary from './ErrorBoundary';
import PageLoader from './Common/PageLoader';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'services/axiosConfig';
import sS from 'utils/sessionStorage';
import { updateAuthUser } from 'redux/actions';
import { landingPage } from 'constants/CommonConstants';
import { allowRole } from 'constants/AllowRole';
import { forceJsonParse } from 'utils/commonHelper';

// デプロイ直後に、使用中のユーザ側にchunk errorが出るのを防止 ※コンポーネントの読み込みに失敗したら1度だけリロードする
const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    // すでに一度リロードされたかどうかをセッションストレージの情報でチェック
    const hasRefreshed = forceJsonParse(sS.get('retry-lazy-refreshed') || 'false');
    // コンポーネントをimport
    componentImport()
      .then((component) => {
        sS.set('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // まだ一度もリロードされていなければリロード
          sS.set('retry-lazy-refreshed', 'true'); // リロードを行ったことをセッションストレージに書き込み
          return window.location.reload(); // リロード
        }
        reject(error); // 一度リロードを行ったあとでもエラーが起こる場合はchunkerrorではないので通常のエラー表示へ
      });
  });

const SignIn = lazy(() => lazyRetry(() => import('routes/SignIn')));
const SignOut = lazy(() => lazyRetry(() => import('routes/SignOut')));
const SummaryMonitoringDevices = lazy(() => lazyRetry(() => import('routes/SummaryMonitoringDevices')));
const ListDeviceOperations = lazy(() => lazyRetry(() => import('routes/ListDeviceOperations')));
const ListLogging = lazy(() => lazyRetry(() => import('routes/ListLogging')));
const ListMasterOrganizations = lazy(() => lazyRetry(() => import('routes/ListMasterOrganizations')));
const EditMasterOrganization = lazy(() => lazyRetry(() => import('routes/EditMasterOrganization')));
// const ListMasterDeviceMakers = lazy(() => lazyRetry(() => import('routes/ListMasterDeviceMakers')))
// const EditMasterDeviceMaker = lazy(() => lazyRetry(() => import('routes/EditMasterDeviceMaker')))
const ListMasterSites = lazy(() => lazyRetry(() => import('routes/ListMasterSites')));
const EditMasterSite = lazy(() => lazyRetry(() => import('routes/EditMasterSite')));
const ListMasterSitesSim = lazy(() => lazyRetry(() => import('routes/ListMasterSitesSim')));
const EditMasterSiteSim = lazy(() => lazyRetry(() => import('routes/EditMasterSiteSim')));
const ListMasterFacilities = lazy(() => lazyRetry(() => import('routes/ListMasterFacilities')));
const EditMasterFacility = lazy(() => lazyRetry(() => import('routes/EditMasterFacility')));
const ListMasterFacilitiesSim = lazy(() => lazyRetry(() => import('routes/ListMasterFacilitiesSim')));
const EditMasterFacilitySim = lazy(() => lazyRetry(() => import('routes/EditMasterFacilitySim')));
const ListMasterDevices = lazy(() => lazyRetry(() => import('routes/ListMasterDevices')));
const EditMasterDevice = lazy(() => lazyRetry(() => import('routes/EditMasterDevice')));
const ListMasterDevicesSim = lazy(() => lazyRetry(() => import('routes/ListMasterDevicesSim')));
const ListMasterChildDevices = lazy(() => lazyRetry(() => import('routes/ListMasterChildDevices')));
const EditMasterChildDevice = lazy(() => lazyRetry(() => import('routes/EditMasterChildDevice')));
const ListMasterChildDevicesSim = lazy(() => lazyRetry(() => import('routes/ListMasterChildDevicesSim')));
const ListMasterAccounts = lazy(() => lazyRetry(() => import('routes/ListMasterAccounts')));
const EditMasterAccount = lazy(() => lazyRetry(() => import('routes/EditMasterAccount')));
const ListMasterCooperationSystems = lazy(() => lazyRetry(() => import('routes/ListMasterCooperationSystems')));
const EditMasterCooperationSystem = lazy(() => lazyRetry(() => import('routes/EditMasterCooperationSystem')));
const ListMasterCooperationSystemsSim = lazy(() => lazyRetry(() => import('routes/ListMasterCooperationSystemsSim')));
const EditMasterCooperationSystemSim = lazy(() => lazyRetry(() => import('routes/EditMasterCooperationSystemSim')));
const ListMasterMasterCooperationSystems = lazy(() => lazyRetry(() => import('routes/ListMasterMasterCooperationSystems')));
const EditMasterMasterCooperationSystem = lazy(() => lazyRetry(() => import('routes/EditMasterMasterCooperationSystem')));
const ListMasterDiamondConnections = lazy(() => lazyRetry(() => import('routes/ListMasterDiamondConnections')));
const EditMasterDiamondConnection = lazy(() => lazyRetry(() => import('routes/EditMasterDiamondConnection')));
const ListMasterDiamondFacilities = lazy(() => lazyRetry(() => import('routes/ListMasterDiamondFacilities')));
const EditMasterDiamondFacility = lazy(() => lazyRetry(() => import('routes/EditMasterDiamondFacility')));
const ListMasterDiamondDevices = lazy(() => lazyRetry(() => import('routes/ListMasterDiamondDevices')));
const EditMasterDiamondDevice = lazy(() => lazyRetry(() => import('routes/EditMasterDiamondDevice')));
const Simulator = lazy(() => lazyRetry(() => import('routes/Simulator')));

const Page404 = lazy(() => import('routes/Pages/404'));

const SuspenseComponent = (props) => {
  const { element, sendProps } = props;
  const component = { ...element, props: sendProps };
  return <Suspense fallback={<PageLoader />}>{component}</Suspense>;
};

const RestrictedRoute = (props) => {
  const { element, accessAllowRole } = props;
  const location = useLocation();
  const [onLoad, setOnLoad] = useState(true);
  const retCheck = useRef({});
  const userData = useRef({});
  const currentPath = useRef();
  const dispatch = useDispatch();

  // 遷移のたびにログインチェック;
  useEffect(() => {
    // F5時axios.defaults.headers.common消えてしまうのでlogin-check時に消えてないかチェック
    const id_token = sS.get('id_token');
    const login_token = sS.get('login_token');
    const authHeaderCheck = () => {
      if (!!id_token && !!login_token) axios.defaults.headers.common['Authorization'] = id_token + ':' + login_token;
    };

    const loginCheck = async () => {
      setOnLoad(true);
      try {
        const { data } = await axios.post('im-login-check', null);
        // result: falseならログアウト
        if (!data.result) {
          retCheck.current = { result: data.result, error: data.err };
          currentPath.current = location.pathname;
          return setOnLoad(false);
        }
        // result: true正常
        retCheck.current = { result: data.result };
        currentPath.current = location.pathname;
        userData.current = data.user;
        // 返却されたユーザ情報は毎回セッションストレージとreduxに書き込み=改ざん防止
        sS.set('id_token', data.user?.rowKey);
        sS.set('organizations_id', data.user?.im_organizations_rowKey);
        sS.set('organizations', data.user?.im_organizations_name);
        sS.set('name', data.user?.name);
        sS.set('kana', data.user?.kana);
        sS.set('mail', data.user?.mail);
        sS.set('role', data.user?.role);
        dispatch(
          updateAuthUser({
            id_token: data.user?.rowKey,
            organizations_id: data.user?.im_organizations_rowKey,
            organizations: data.user?.im_organizations_name,
            name: data.user?.name,
            kana: data.user?.kana,
            mail: data.user?.mail,
            role: data.user?.role,
          }),
        );
        return setOnLoad(false);
      } catch (e) {
        retCheck.current = { result: false, error: 'ログインチェック通信ができませんでした' };
        currentPath.current = location.pathname;
        setOnLoad(false);
      }
    };
    if (!axios.defaults.headers.common['Authorization']) authHeaderCheck();
    loginCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // loginCheck後の処理
  const AfterLoading = () => {
    // loginCheck結果falseの場合
    if (!retCheck.current.result)
      return <Navigate to="/signout" state={{ from: props.location, search: location.search, message: { message: retCheck.current.error, severty: 'error' } }} replace={true} />;
    // 権限確認 ※権限なしの場合は、デフォルトランディングページに強制送還
    if (!accessAllowRole.includes(userData.current.role))
      return <Navigate to={landingPage[userData.current.role]} state={{ message: { message: 'アクセス権限がありません', severty: 'error' } }} replace={true} />;
    return <SuspenseComponent element={element} />;
  };

  // loginCheck処理中はローダー表示
  if (onLoad || currentPath.current !== location.pathname) return <PageLoader />;
  else return AfterLoading();
};

const RouterConfig = () => {
  const location = useLocation();

  // pathなしはsigninページへ
  if (location.pathname === '' || location.pathname === '/') return <Navigate to={'/signin'} replace={true} />;

  // アクセス権限
  const monitorAccessAllowRole = allowRole[location?.pathname]?.monitor || [];
  const searchAccessAllowRole = allowRole[location?.pathname]?.search || [];
  const editAccessAllowRole = Array.from(
    new Set(
      Object.keys(allowRole[location?.pathname] || {})
        ?.map((category) => allowRole[location?.pathname]?.[category])
        ?.flat() || [],
    ),
  );

  return (
    <ErrorBoundary>
      <Routes>
        {/* ログイン認証 */}
        <Route path="/signin" element={<SuspenseComponent element={<SignIn />} />} />
        <Route path="/signout" element={<SuspenseComponent element={<SignOut />} />} />
        {/* モニタリング */}
        <Route path="/summary-monitoring-devices" element={<RestrictedRoute element={<SummaryMonitoringDevices />} accessAllowRole={monitorAccessAllowRole} />} />
        <Route path="/list-device-operations" element={<RestrictedRoute element={<ListDeviceOperations />} accessAllowRole={monitorAccessAllowRole} />} />
        <Route path="/list-logging" element={<RestrictedRoute element={<ListLogging />} accessAllowRole={monitorAccessAllowRole} />} />
        <Route path="/simulator" element={<RestrictedRoute element={<Simulator />} accessAllowRole={monitorAccessAllowRole} />} />
        {/* マスター検索、管理 */}
        <Route path="/master-organizations" element={<RestrictedRoute element={<ListMasterOrganizations />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-organization" element={<RestrictedRoute element={<EditMasterOrganization />} accessAllowRole={editAccessAllowRole} />} />
        {/* <Route path="/master-device-makers" element={<RestrictedRoute element={<ListMasterDeviceMakers />} />} />
        <Route path="/edit-master-device-maker" element={<RestrictedRoute element={<EditMasterDeviceMaker />} />} /> */}
        <Route path="/master-sites" element={<RestrictedRoute element={<ListMasterSites />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-site" element={<RestrictedRoute element={<EditMasterSite />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-sites-sim" element={<RestrictedRoute element={<ListMasterSitesSim />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-site-sim" element={<RestrictedRoute element={<EditMasterSiteSim />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-facilities" element={<RestrictedRoute element={<ListMasterFacilities />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-facility" element={<RestrictedRoute element={<EditMasterFacility />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-facilities-sim" element={<RestrictedRoute element={<ListMasterFacilitiesSim />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-facility-sim" element={<RestrictedRoute element={<EditMasterFacilitySim />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-devices" element={<RestrictedRoute element={<ListMasterDevices />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-device" element={<RestrictedRoute element={<EditMasterDevice />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-devices-sim" element={<RestrictedRoute element={<ListMasterDevicesSim />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/master-child-devices" element={<RestrictedRoute element={<ListMasterChildDevices />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-child-device" element={<RestrictedRoute element={<EditMasterChildDevice />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-child-devices-sim" element={<RestrictedRoute element={<ListMasterChildDevicesSim />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/master-accounts" element={<RestrictedRoute element={<ListMasterAccounts />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-account" element={<RestrictedRoute element={<EditMasterAccount />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-cooperation-systems" element={<RestrictedRoute element={<ListMasterCooperationSystems />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-cooperation-system" element={<RestrictedRoute element={<EditMasterCooperationSystem />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-cooperation-systems-sim" element={<RestrictedRoute element={<ListMasterCooperationSystemsSim />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-cooperation-system-sim" element={<RestrictedRoute element={<EditMasterCooperationSystemSim />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-diamond-connections" element={<RestrictedRoute element={<ListMasterDiamondConnections />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-diamond-connection" element={<RestrictedRoute element={<EditMasterDiamondConnection />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-diamond-facilities" element={<RestrictedRoute element={<ListMasterDiamondFacilities />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-diamond-facility" element={<RestrictedRoute element={<EditMasterDiamondFacility />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-diamond-devices" element={<RestrictedRoute element={<ListMasterDiamondDevices />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-diamond-device" element={<RestrictedRoute element={<EditMasterDiamondDevice />} accessAllowRole={editAccessAllowRole} />} />
        <Route path="/master-master-cooperation-systems" element={<RestrictedRoute element={<ListMasterMasterCooperationSystems />} accessAllowRole={searchAccessAllowRole} />} />
        <Route path="/edit-master-master-cooperation-system" element={<RestrictedRoute element={<EditMasterMasterCooperationSystem />} accessAllowRole={editAccessAllowRole} />} />
        {/* その他 */}
        <Route path="*" element={<SuspenseComponent element={<Page404 />} />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default RouterConfig;
